@import "../../styles/utils";

.homepageImageCard {
  margin-top: 3rem;
  padding: 3.2rem;
  border-radius: 8px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,0.5);
  height: 35.3rem;

  @include small {
    width: 321px;
    height: 440px;
  }

  .departure {
    font-weight: bold;
    font-size: 55px;
    line-height: 67px;
    color: #FFFFFF;
  }

  .arrival {
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-left: 0.4rem;
  }
}

.offerFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;

  .price {
    font-weight: bold;

    span {
      font-weight: lighter;
      margin-left: 0.4rem;
      font-size: 12px;
      line-height: 15px;
      color: #FFFFFF;
    }

    font-size: 28px;
    line-height: 34px;
    color: #FFFFFF;
  }
}

