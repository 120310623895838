@import "../../styles/utils";

.ant-btn-loading-icon {
  width: 20px!important;
  margin-right: -20px!important;
}

.ant-card-body {
  height: 400px;
  padding: 0;
}

.ant-row {
  display: grid;
  margin-bottom: 5px;
}

.ant-col-4 {
  max-width: fit-content;
}

.ant-form-item-label {
  text-align: start;
}

.ant-form-item-label > label {
  color: rgba(55, 70, 101, 0.6);
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 14px;
  font-size: 12px;
}

.ant-form-item {
  margin-bottom: 0;
  padding: 0;
}

.ant-col-sm-offset-8 {
  margin: 0;
}

.homepage-form {
  display: flex;
  .ant-select-selection-item {
    font-size: 20px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
  }

  .ant-select-selector {
    border: none !important;
    border-right-width: 0 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  .ant-picker {
    padding: 0;
  }
}

.ant-select-dropdownn {
  left: 490px !important;
  top: 204px !important;
  width: 300px !important;
  min-width: 300px !important;
  border-radius: 10px;
  padding-top: 12px;
}

.ant-select-arrow {
  font-size: 14px;
}

.ant-picker-dropdown-range {
  display: flex;
  margin: 0;
  left: 572px !important;
  top: 755px !important;
}

.ant-btn {
  background: #F7F7F9;
  height: 50px;
  border-radius: 4px;
  font-weight: 700;
  color: #374665;
  border-color: transparent;
}

.ant-drawer-close {
  padding-top: 16px;
}

.ant-btn:hover{
  background: #fff;
  border: 1px solid #0D3B66;
}

.ant-btn:focus{
  background: #fff;
  color: #0D3B66;
}

.ant-picker-separator {
  display: none;
}

.ant-picker-dropdown-placement-bottomLeftt {
  left: 490px !important;
  top: 205px !important;
}

.ant-select-item-option {
  padding: 12px 24px;
}

.ant-select-item-option-content {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.87);
}

.rc-virtual-list-holder {
  max-height: 488px !important;
}

.homepage-form {
  .ant-select-selector {
    border: none !important;
    border-right-width: 0 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

.ant-steps {
  height: 100%;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 3px;
  background: #C8C8D4;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 15px;
  width: 1px;
  height: 100%;
  padding: 32px 0 0;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
  font-weight: 700;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
  font-weight: 700;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #11BF76;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #11BF76;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #DB0001;
  border-color: #DB0001;

  &:hover {
    border-color: #06A0B8;
  }
}

.ant-steps-item-title {
  padding: 0 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #11BF76;
  font-weight: 700;
  width: max-content;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #11BF76;
  font-weight: 700;
  width: max-content;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #11BF76;
  border-color: #11BF76;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #11BF76 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ffffff;
}

.anticon svg {
  display: inline-block;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #06A0B8;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title {
  color: #06A0B8;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #06A0B8 !important;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 35px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #11BF76;
  margin-left: 5.5px;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #DB0001 !important;
  margin-left: 5.5px !important;
}

.ant-btn-primary {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 130px;
  height: 68px;
  background: #FFFFFF;
  border-style: none;
  border-radius: 8px;
  margin: 0 15px 0 0;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-primary:hover {
  background: #fff;
  color: #0D3B66;
  border: 1px solid #197AC0;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  color: #374665;
  border: 1px solid #DFDFDF;
  background: #F7F7F9;
}

.ant-tooltip-inner {
  min-width: 250px;
  min-height: 250px;
  background-color: rgba(13, 59, 102, 0.8);
  border-radius: 4px;
  padding: 50px 25px;
  text-align: center;
}

.passengerFormRowDatePicker {
  border: none;
  padding: 0;
  input {
    font-size: 2rem;
  }
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-range .ant-picker-active-bar {
  margin: 0;
  background: #DB0001;
}

.ant-picker-range-separator {
  padding: 0 38px;
}

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  display: none;
}

.ant-picker-header {
  font-weight: bold;
  font-size: 16px;
  padding-top: 10px;
}

.ant-picker-header-view {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #DB0001;
}

.ant-picker-date-panel .ant-picker-content th {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #252525;
}

.ant-picker-date-panel .ant-picker-body {
  padding-left: 14px;
}

.ant-picker-body {
  font-size: 14px;
}

.-vrcirtual-list-scrollbar-thumb {
  height: 150px !important;
}

.ant-form-item-children-icon{
  display: none;
}

.ant-form-item-label > label::after{
  display: none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #DB0001;
  span {
    color: white;
  };
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  width: 190px;
  margin-top: 0;
  margin-left: -11px;
  padding: 0;
  font-size: 18px;
  color: #7E7C7C;
}
