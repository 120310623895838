@import "../../../styles/utils";

.payment-methods_container {
  position: relative;
  height: 100%;
  padding-bottom: 20px;

  .payment-methods_cards-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include small{
      flex-direction: column;
    }
  }

  .payment-methods {
    position: relative;
    padding: 3rem 3rem 8rem 3rem;
    height: 100%;
    overflow: scroll;

    h2 {
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 27px;
      color: #374665;
    }
  }
}

.payment-card {
  display: flex;
  width: 300px;
  margin: 8px;
  height: 150px;
  box-shadow: 0 0 4px lightgray;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  cursor: pointer;

  @include small{
    flex: 1;
  }
  .payment-card_logo {
    width: auto;
    height: 4rem;
    margin-left: auto;
  }

  .payment-card_card {
    width: 30px;
    height: 25px;
    border-radius: 5px;
  }

  .payment-card_logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .payment-card_text {
    text-align: center;
    font-size: 2rem;
    margin-top: 20px;
    font-weight: 200;
    border-bottom: 1px solid lightgray;
  }

  .payment-card-pay-now {
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 4px;
  }
}

.manual-payments-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include small{
    flex-direction: column;
  }
}

.payment-card:hover {
  background-color: #f2f2f2;
  transition: .3s;
}

.m-b-3 {
  margin-bottom: 3rem;
}