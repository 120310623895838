@import "../../../styles/utils";

.overview-container {
  position: relative;
  height: 100%;

  .overview {
    position: relative;
    padding: 3rem 3rem 8rem 3rem;
    height: 100%;
    overflow: scroll;

    h2 {
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 27px;
      color: #374665;
    }
  }

  .information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
    width: 100%;
    border-radius: 8px;
    background: #F7F7F9;
    border: 1px solid #E6E4E4;
    padding: 20px;


    .flight-info {
      padding: 16px;
      width: 100%;
      background: #FFFFFF;
      color: #374665;
      border-radius: 8px 8px 0 0;
      box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
      -webkit-box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
      -moz-box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);

      p {
        font-size: 12px;
        color: #374665;
        opacity: 0.5;
      }

      h2 {
        font-weight: 700;
      }
    }

    .passengers-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      width: 100%;
      background: #FFFFFF;
      color: #374665;
      margin: 10px 0 10px 0;
      border-radius: 8px;
      box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
      -webkit-box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
      -moz-box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);

      p {
        font-size: 12px;
        font-weight: bold;
        color: #374665;
        opacity: 0.5;
      }

      h3 {
        padding-right: 100px;
      }
    }
  }

  .confirmation-code-info {
    background: #F3FAFB;
    width: 100%;
    color: #06A0B8;
    padding: 8px 24px 8px 24px;
    font-size: 12px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
    -webkit-box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);
    -moz-box-shadow: 0px 3px 9px -1px rgba(133, 133, 133, 0.3);

    span {
      float: right;
    }
  }


  .contact-style {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    background: #F7F7F9;
    border: 1px solid #E6E4E4;
    border-radius: 8px;

    h3 {
      color: #374665;
      font-weight: bold;
    }

    .contact-style_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .data-style {
    font-size: 2rem;
    line-height: 24px;
    color: #374665;
  }

  .input-style {
    font-size: 2rem;
    color: #374665;
    border: 1px solid #DFDFDF;
    height: 30px;
    padding-left: 7px;
    padding-right: 7px;
  }

  .input-style:focus {
    border-radius: 2px;
    border: 1px solid #40a9ff;
  }

  .ant-divider-horizontal {
    margin: 3.3rem 0;
  }

  .colStyle {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 5rem;
  }

  .rowStyle {
    display: flex;

    @include medium {
      flex-direction: column;
    }
  }

  .passengerLabel {
    width: 100px;
    font-size: 1rem;
    color: rgba(55, 70, 101, 0.6);

    label {
      margin-bottom: 0;
    }
  }

  .data {
    font-size: 1.5rem;
    line-height: 24px;
    color: #374665;
  }

  .terms-conditions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3.3rem;
    margin-bottom: 5rem;
    height: 70px;
    width: 400px;
    float: right;
    background: #F7F7F9;
    border: 1px solid #E6E4E4;
    border-radius: 8px;
    padding: 0 0.5rem;

    @include medium {
      width: 100%;
      height: 70px;
    }

    @include small {
      padding: 15px;
    }

    p {
      font-weight: bold;
      font-size: 1.7rem;
      color: #374665;
      margin-left: 8px;
    }
  }

  .terms-conditions-style {
    display: flex;

    a {
      font-weight: bold;
      font-size: 1.7rem;
      color: #374665;
      margin-left: 8px;
      text-decoration: underline;

      &:hover {
        color: #06A0B8;
        text-decoration: underline;
      }

      @include medium {
        font-size: 1.5rem;
      }

      @include small {
        font-size: 1.4rem;
      }
    }
  }

  .terms-conditions .ant-checkbox-wrapper {
    @include small {
      font-size: 12px;
    }
  }
}

.ant-modal-content {
  height: 650px !important;
  overflow: scroll !important;
}