.benefits {
  padding: 2.4rem 3rem 1.4rem 3rem;
  margin: auto;
  margin-top: 30px;

  h1 {
    display: flex;
    justify-content: center;
    font-size: 30px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 60px;
  }

  img {
    margin-right: 17px;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  p {
    font-size: 15px;
    line-height: 15px;
    color: #7E7C7C;
  }

  .benefit {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  .divider {
    min-width: 50% !important;
    background: #DB0001 !important;
    margin: 52px 0 52px 0;
  }
}



