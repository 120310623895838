@import "utils";

.dateRangeFormItem {
  padding: 8px 20px;

  .ant-row {
    margin-bottom: 0;
  }

  label {
    width: 100%;
    display: flex;
    color: #DB0001;
    font-weight: bold;
    font-size: 10px;
  }

  .custom-date-range-picker {
    display: flex;

    .ant-picker-range-separator {
      padding: 0;
      width: 0.6px;
      height: 59px;
      background-color: black;
      position: absolute;
      top: -28px;
      right: 50%;
    }

    .ant-picker-suffix {
      margin-right: 3rem;
    }

    .ant-picker-input {
      &:first-child {
        padding: 0;
      }
      padding-left: 5.6rem;
    }

    .ant-picker-active-bar {
      display: none;
    }

    &.disabled-return-date {
      .ant-picker-input:nth-child(3) {
        opacity: .6;
        pointer-events: none;
      }
    }

    &.disabled {
      pointer-events: none;
      .ant-picker-input {
        opacity: .6;
        pointer-events: none;
      }
    }
  }
}

.ant-picker-panel-container {
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
}

.custom-date-range-picker-dropdown {
  .ant-picker-panel {
    border: none;

    .ant-picker-header {
      border-bottom: none;

      .ant-picker-header-super-prev-btn,
      .ant-picker-header-super-next-btn {
        display: none;
      }
    }

    .ant-picker-cell {
      &:not(.ant-picker-cell-in-view) {
        visibility: hidden;
      }
    }

    .ant-picker-cell-in-range {
      &:before {
        background-color: #DB0001;
        opacity: 30%;
      }
    }

    .ant-picker-cell-range-end {
      .ant-picker-cell-inner {
        color: white;
      }
    }

    .ant-picker-cell-range-start {
      .ant-picker-cell-inner {
        color: white;
      }
    }

    &:first-of-type {
      .ant-picker-header-view {
        padding-right: 1rem;
      }

      .ant-picker-header-next-btn {
        display: none;
      }
    }

    &:nth-of-type(2) {
      .ant-picker-header-view {
        padding-left: 1rem;
      }

      .ant-picker-header-prev-btn {
        display: none;
      }
    }
  }

  .ant-picker-cell {
    .ant-picker-cell-inner {
      font-weight: normal;
      font-size: 11px;
    }
  }

  .ant-picker-cell-disabled {
    &:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-end) {
      &:before {
        background: white;
      }
    }

    .ant-picker-cell-inner {
      color: #7E7C7C;
    }
  }

  .ant-picker-cell.ant-picker-cell-in-view:not(.ant-picker-cell-disabled):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-start) {
    .ant-picker-cell-inner {
      color: black;
      font-weight: normal;
      font-size: 11px;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-today.ant-picker-cell-inner::before {
    font-weight: bold;
  }

  .ant-picker-cell {
    &.ant-picker-cell-range-start,
    &.ant-picker-cell-range-end {
      .ant-picker-cell-inner {
        background-color: #DB0001;
        font-weight: bold;

        &:before {
          border-color: #DB0001;
        }
      }
    }
  }

  .ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
  }
}

.rc-virtual-list-holder {
  @include medium {
    max-height: unset !important;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: transparent;
}

.custom-date-range-picker-dropdown .ant-picker-cell-disabled .ant-picker-cell-inner:hover {
  background-color: #DB0001;
}
