@import "../../styles/utils";

.passengerFormRowTitle {
  line-height: 1.6rem;
  margin-bottom: 4.8rem;
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.passengerFormRow {
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

.row {
  display: flex;
  //box-shadow: 0px 3px 9px -1px rgba(133,133,133,0.3);
  //-webkit-box-shadow: 0px 3px 9px -1px rgba(133,133,133,0.3);
  //-moz-box-shadow: 0px 3px 9px -1px rgba(133,133,133,0.3);
  //border-radius: .8rem;

  @include medium {
    flex-direction: column;
  }
}

.birthdayInfo {
  display: flex;
  margin: 2rem 0;

  .space {
    margin-right: 12rem;
  }

  .day, .month, .year {
    height: 37px;
    border-radius: 5px;
    border: 0.6px solid #7E7C7C;
  }
}

.col {
  flex: 1;

  display: flex;
  flex-direction: column;

  position: relative;
  padding: 1.2rem 2.4rem;
  border: 1px solid #DFDFDF;
  background-color: white;

  &:first-of-type {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem;
  }

  &:last-of-type {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem;

    input {
      padding-bottom: .2rem;
      line-height: 2.4rem;
      outline: none;
    }
  }

  @include medium {
      margin-bottom: .8rem;
      border-radius: .8rem;
  }

  &.hasError {
    border-color: indianred;
  }
}

.passengerFormRowInput {
  width: 394.959px;
  height: 37px;
  border-radius: 5px;
  border: 0.6px solid #7E7C7C;
  font-size: 12px;
  padding: 11px;
  margin-right: 52px;

  &::placeholder {
    color: #7E7C7C;
    opacity: 0.5;
  }

  span {
    display: none;
  }

  input {
    font-size: 2rem;
    line-height: 2.4rem;

    &::placeholder {
      color: #374665;
      opacity: 0.5;
    }

    outline: none;
    border: none;
    box-shadow: none;
  }
}

.passengerFormRowLabel {
  margin-top: .4rem;
  margin-left: .2rem;
  margin-bottom: .8rem;

  font-size: 1.2rem;
  line-height: 1.5rem;
  color: rgba(55, 70, 101, 0.6);
}

.validationMessage {
  position: absolute;
  bottom: 0;
  padding-left: .2rem;
  font-size: 1.2rem;
  color: indianred;
}

.radioButtons {
  position: relative;
  display: flex;
  align-items: center;
  height: 37px;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  margin-top: 1rem;

  .validationMessage {
    left: 0;
    bottom: -.85rem;
  }

  &.hasError {
    input+span {
      border-color: indianred;
    }
  }
}


