@import 'styles/typography.css';
@import 'styles/bootstrap.css';
@import "styles/antd-custom";
@import "styles/utils";

html {
  font-size: 9px;
}

body {
  margin: 0;
  font-family: Museo, Sans !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-primary {
  height: 4.2rem !important;
  color: white !important;
  border-radius: 5px !important;
  border-color: #DB0001 !important;
  background-color: #DB0001 !important;

  &:disabled {
    border-color: lightgrey !important;
    background-color: lightgrey !important;
  }
}

.button-outline {
  height: 4.2rem !important;
  color: #06A0B8 !important;
  border-radius: 5px !important;
  border-color: #06A0B8 !important;
  background-color: white !important;
}

.button-text-primary {
  height: 4.2rem !important;
  color: #DB0001 !important;
  border-radius: 5px !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.select-input-header {
  display: none;
  align-items: center;

  padding: 0;
  background-color: #0D3B66 !important;

  color: white;
  font-size: 2.2rem;
  font-weight: bold;

  @include medium {
    display: flex;
  }

  svg {
    margin-right: 2rem;
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;

    padding: 2.2rem;

    color: white;
    font-size: 2.2rem;
    font-weight: bold;

    svg {
      margin-right: 2rem;
    }
  }
}

.range-picker-panel-container {
  .select-input-header {
    padding: 2.2rem;
  }
}
