@import '../../styles/utils';

.flightPreviewTitle {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.flightPreview {
  overflow: hidden;
  border-top: 1px solid #DB0001 !important;
  border-bottom: 1px dashed #DB0001;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
}

.top {
  display: flex;
  align-items: center;
  padding: 3.4rem 0;

  svg {
    margin: 0 7rem;
  }

  .airportName {
    font-size: 2.8rem;
    line-height: 3.3rem;
    color: #DB0001;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  padding: 30px 0 40px 0;
  flex-wrap: wrap;
}

.flightPreviewDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 2;

  .flightInfo {
    padding: 2rem 0;
  }

  .horizontal {
    position: relative;
    flex: 1;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: #DB0001;
      }
    }

    .flightTime {
      position: absolute;
      top: 100%;
      left: 50%;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      transform: translate(-50%, 150%);
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      border-top: 1px solid #DB0001;
      width: 100%;
    }
  }

  .date {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .time {
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #DB0001;
  }

  .airport {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }
}