@font-face {
  font-family: 'Museo';
  font-style:  normal;
  font-weight: 400;
  src: url("../assets/fonts/MuseoSans-300.otf") format("opentype");
}

@font-face {
  font-family: 'Museo';
  font-style:  italic;
  font-weight: 400;
  src: url("../assets/fonts/MuseoSans-300Italic.otf") format("opentype");
}

@font-face {
  font-family: 'Museo';
  font-style:  normal;
  font-weight: 900;
  src: url("../assets/fonts/MuseoSans_900.otf") format("opentype");
}
@font-face {
  font-family: 'Museo';
  font-style:  normal;
  font-weight: 700;
  src: url("../assets/fonts/MuseoSans_700.otf") format("opentype");
}

@font-face {
  font-family: 'Museo';
  font-style:  italic;
  font-weight: 400;
  src: url("../assets/fonts/MuseoSans-900Italic.otf") format("opentype");
}



