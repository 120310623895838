.flightRow {
  overflow: hidden;
  border-top: 1px solid #DB0001 !important;
  border-bottom: 1px dashed #DB0001;
}

.infoContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  padding: 30px 0 40px 0;
  flex-wrap: wrap;
}

.flightDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 2;

  .flightInfo {
    padding: 2rem 0;
  }

  .horizontal {
    position: relative;
    flex: 1;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: #DB0001;
      }
    }

    .flightTime {
      position: absolute;
      top: 100%;
      left: 50%;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      transform: translate(-50%, 150%);
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      border-top: 1px solid #DB0001;
      width: 100%;
    }
  }

  .date {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .time {
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #DB0001;
  }

  .airport {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }
}

.selection {
  width: 133px;
  height: 142px;
  background: #DB0001;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 50px;

  .select {
    width: 78.5px;
    height: 24.5px;
    border: 0.6px solid #FFFFFF;
    border-radius: 8px;
    background: transparent;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
  }

  h1 {
    font-size: 28px;
    color: #FFFFFF !important;
    font-weight: 500;
  }

  h3 {
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF !important;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 7px;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
  }
}

.disabled {
  position: relative;
  cursor: default;
  box-shadow: none;

  .top {
    border-bottom: 1px solid #DFDFDF;
  }

  .top, .bottom {
    background-color: #F7F7F9;
  }

  &:hover {
    box-shadow: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //opacity: .3;
    background-color: #F7F7F9;
    border: none;
    z-index: 99;
  }

  .sold {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    color: white;
    font-size: 3rem;
    border-radius: 1rem;
    background-color: #DB0001;
    z-index: 999;
  }
}

.basicInfo {
  height: 175px;
  border-bottom: 1px solid #DB0001;
  padding: 15px;
  background: #F7F7F5;

  p {
    font-size: 20px;
    color: #B1B1B2;
  }

  h1 {
    font-weight: 700;
    font-size: 35px;
    //line-height: 42px;
    color: #000000;
    margin-bottom: 14px;
  }

  h2 {
    font-weight: 700;
    font-size: 38px;
    //line-height: 46px;
    color: #DB0001;
  }

  .benefits {
    display: flex;
    justify-content: space-between;

    div {
      display: flex;

      img {
        height: 21px;
        margin-right: 10px;
        margin-top: 3px;
      }
    }

    p {
      font-size: 19px;
      color: #000000
    }
  }
}
