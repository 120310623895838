$grey-dark: #374665;
$grey: #F7F7F9;
$orange: #F26826;
$dark-blue: #153D67;

.overflow-hidden {
  overflow: hidden;
}

.ml-12 {
  margin-left: 1.2rem;
}

.mt-75 {
  margin-top: 7.5rem;
}

@mixin small {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin medium {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin large {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin extra-large {
  @media (max-width: 1200px) {
    @content;
  }
}
