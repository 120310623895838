@import "../../styles/utils";

.flightBox {
  width: 135.78px;
  height: 60px;
  padding: 2px;
  cursor: pointer;
  border: 0.6px solid #7E7C7C;
  border-radius: 8px;
  text-align: center;

  &.disabled {
    cursor: default;
    background-color: #F5F5F5;
    border: 0.6px solid #7E7C7C;
    border-radius: 8px;
  }

  &.selected {
    background: #DB0001;
    border-radius: 8px;
    border: #DB0001;

    .day {
      color: #fff;
    }

    .date {
      color: #fff;
    }

    .price {
      color: #fff;
      font-weight: 700;

    }
  }

  @include medium {
    width: 85%;
    margin: auto;
  }

}

.day {
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

.date {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #DB0001;
}

.price {
  font-size: 12px;
  line-height: 15px;
  color: #7E7C7C;
}
